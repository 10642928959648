// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.


$material-icons-font-path: '~material-icons/iconfont/';
@use '~@angular/material' as mat;
@import '~material-icons/iconfont/material-icons.scss';
@import '~@fontsource/roboto/index.css';
@import '~@fontsource/roboto/300.css';
@import '~@fontsource/roboto/400.css';
@import '~@fontsource/roboto/500.css';

@import 'palette';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

$ypography: mat.define-typography-config(
  $font-family: 'Roboto, sans-serif',
  $headline:      mat.define-typography-level(20px, 48px, 700),
  $body-2:        mat.define-typography-level(20px, 36px, 500),
  $body-1:        mat.define-typography-level(20px, 21px, 400),
  $caption:       mat.define-typography-level(14px, 18px, 400),
  $button:        mat.define-typography-level(16px, 18px, 500),
);

@include mat.core($ypography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ypo-primary: mat.define-palette($ypo-primary);
$ypo-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ypo-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ypo-theme: mat.define-light-theme((
  color: (
    primary: $ypo-primary,
    accent: $ypo-accent,
    warn: $ypo-warn,
  )
));

@mixin my-theme($theme) {
.zzz {
  color: #88C76C !important; 
  background-color: whitesmoke !important;
  font-size: 16px;
  line-height: 18px;
  padding: 20px !important;
  text-align:justify;
}
.mat-list-option { height:30px !important}
$primary: map-get($ypo-theme, primary);
$warn: map-get($ypo-theme, warn);
$accent: map-get($ypo-theme, accent);
$foreground: map-get($ypo-theme, foreground);
.primary { color: mat.get-color-from-palette($primary) !important; }
.warn { color: mat.get-color-from-palette($warn) !important; }
.accent { color: mat.get-color-from-palette($accent) !important; }
.reverseprimary {
  background-color: mat.get-color-from-palette($primary) !important;
  color: mat.get-color-from-palette($primary, default-contrast) !important;
}
.reversewarn {
  background-color: mat.get-color-from-palette($warn) !important;
  color: mat.get-color-from-palette($warn, default-contrast) !important;
}
.mat-menu-panel { max-width: inherit !important}
.zzmat-menu-content { background: mat.get-color-from-palette($primary); 
  .mat-menu-item:hover {
    background: mat.get-color-from-palette($primary, A700) !important;
  }
  .mat-menu-item, .mat-icon {
    font-weight: 500;
    color: mat.get-color-from-palette($primary, default-contrast) !important;
  }
} 
.mat-header-cell, .mat-cell {
  padding: 0 5px !important;
}
// .mat-horizontal-stepper-header { padding: 0 8px !important }
// .mat-stepper-horizontal-line { margin: 0 !important}
button.mat-fab {
  &.small { width:40px !important; height: 40px !important;
  .mat-button-wrapper { padding:0}}
  // &.medium { width:56px !important; height: 56px !important;}
  &.large { width:70px !important; height: 70px !important;}
}

  /* .mat-form-field-invalid { color:blueviolet !important } */
  .visits {
    .mat-pseudo-checkbox {
      display: none !important;
    }
    .mat-list-text { padding-left:0 !important }
  }
  .cdk-overlay-container {
    z-index: 99999;
  }
  .p1desktop .mat-horizontal-content-container {
    position: relative;
    height: calc(100% - 96px);
    min-height: calc(100% - 96px);
    max-height: calc(100% - 96px);
    .mat-horizontal-stepper-content {
      // max-height:calc(100vh - 183px);
      overflow: auto;
    }
    .navig {
      position: absolute;
      padding:5px 0;
      bottom: 0;
      left:0;
      right:0;
      border-top:1.5px solid mat.get-color-from-palette($primary, default-contrast);
    }
  }
  .p1mobile .mat-horizontal-content-container {
    height: calc(100% - 96px);
    max-height: calc(100% - 96px);
    min-height: calc(100% - 96px);
    position: relative;
    .mat-horizontal-stepper-content {
      // max-height:calc(100% - 183px);
      overflow: auto;
    }
    .navig {
      position: absolute;
      padding:5px 0;
      bottom: 0;
      left:0;
      right:0;
      border-top:2px solid mat.get-color-from-palette($primary, default-contrast);
    }
  }
  .mat-stepper-next .mat-button-wrapper div , .mat-stepper-previous .mat-button-wrapper div {
    padding:4px 0; display: flex;
  }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@mixin tibco-theme($theme) {
  // https://github.com/angular/components/blob/master/guides/duplicate-theming-styles.md
  @include mat.all-component-themes($theme);
  @include my-theme($theme);
}
@include tibco-theme($ypo-theme);

/* You can add global styles to this file, and also import other style files */

@mixin size-icon($size) {
  height:$size !important;
  width:$size !important;
  font-size:$size !important;
  line-height:$size !important;
}
.mat-icon.maticon200 { @include size-icon(200px) }
.mat-icon.maticon100 { @include size-icon(100px) }
.mat-icon.maticon50 { @include size-icon(50px) }
.mat-icon.maticon40 { @include size-icon(40px) }
.mat-icon.maticon30 { @include size-icon(30px) }
.mat-icon.maticon20 { @include size-icon(20px) }

.codesample {
  position:relative;
  font-family: monospace;
  background-color: #F6F8FA;
  color:#24292E;
  border-radius: 6px;
  font-size: 85%;
  line-height: 1.45;
  overflow: auto;
  padding: 16px;
  white-space: nowrap;
  .tag {
    color: #22863A;
    &.start:before { content:'<'; }
    &.end:after { content:'>'; }
    &.close:after { content:'>'; }
    &.close:before { content:'</'; }
  }

  .attr {
    color: #005CC5;
    &:before { content: " "}
    &:after { color:#24292E; content: "="}
  }
  .arg {
    color: red; // #032F62
    &:before { color:#24292E; content: "\""}
    &:after { color:#24292E; content: "\""}
  }
}

html, body {
    height: 100%;
    margin: 0;
    -webkit-text-size-adjust: none;
    touch-action: pan-y; /*prevent user scaling*/
  }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
